<template>
  <div>
    <!-- Test Task Content -->
    <v-sheet
        v-if="entity.uuid"
        :color="wsLIGHTCARD"
        class="wsRoundedHalf pa-4 mb-5"
    >


      <v-sheet
          v-for="(item,i) in testInfoBoxesItems" :key="i"
          :color="item.color || null"
          class="wsRoundedSemi mb-1 px-4 py-2 d-flex align-center"
      >
        <v-icon :color="item.iconColor || wsATTENTION" class="mr-2">{{ item.icon || 'mdi-cog' }}</v-icon>
        <h5 :style="`color : ${item.fontColor || wsDARKER}`">
          {{ item.text }}
        </h5>
      </v-sheet>

      <ws-button
          v-if="(getStartNewTest || getTestInProcess) && !waitingForManualCheck"
          :to="businessDashLink('courses/test/' + entity.test_id)"
          :label-raw="getTestStartText"
          block
          class="mt-4"
      />

    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "videoTaskViewer",
  props : {
    entity : {
      type : Object,
      default() { return {} }
    }
  },
  computed : {
    waitingForManualCheck() {
      if (!this.entity.student_test) {
        return false
      }

      if (!this.entity.student_test.length) {
        return false
      }

      const lastTry = this.entity.student_test[this.entity.student_test.length - 1]

      return lastTry.status === 'manual_check'
    },
    testInfoBoxesItems() {

      if (this.getTestInProcess) {
        return [{
          text : this.$t('TestInProcess'),
          icon : 'mdi-progress-check',
          color : this.wsBACKGROUND,
          iconColor : this.wsACCENT
        }]
      }

      if (this.waitingForManualCheck) {
        return [
          { text : this.$t('testing.waiting_manual_check') ,
            icon : 'mdi-av-timer',
            color : this.wsBACKGROUND,
            iconColor : this.wsACCENT
          }
        ]
      }



      const passingScoreText = `${this.$t('TaskPassPoints')} - ${this.entity.test_pass_score}%`
      const testDurationText = `${this.$t('testing.test_duration')} - ${ this.$tc('_minutes' , this.entity.test_duration)}`
      const testTriesText = `${this.$t('TriesLeft')} - ${ this.entity.test_tries}`



      let items = [
        { text : passingScoreText ,
          icon : 'mdi-text-box-check-outline'
        },
      ]
      let limitItems = [
        { text : testDurationText ,
          icon : 'mdi-av-timer'
        }
      ]

      if (this.getStartNewTest) {
        limitItems.push( { text : testTriesText ,
          icon : 'mdi-sync-alert'
        })
      }

      if ( this.entity.test_unlimited ) {
        items = [...items, ...limitItems]
      }

      if ( this.entity.student_test) {
        const resultText = `${this.$t('WorstResult')} - ${this.getWorstTestResult}% / ${this.$t('BestResult')} - ${this.getBestTestResult}%`

        items = [...items , {
          text : resultText,
          icon : 'mdi-sync-alert'
        }]

        if ((this.entity.test_unlimited && !this.getTestInProcess && !this.getStartNewTest && !this.getSuccess)) {

          return [
            {
              text : this.$t('TestNoMoreTries'),
              color : this.wsWARNING + 33,
              icon : 'mdi-emoticon-sad-outline',
              iconColor : this.wsWARNING,
              fontColor : this.wsWARNING
            },
            {
              text : resultText,
              icon : 'mdi-sync-alert'
            }
          ]
        }
      }

     if (this.getSuccess) {
       items.unshift(
           {
             text : this.$t('testing.test_success'),
             icon : 'mdi-check-circle-outline' ,
             color : this.wsSUCCESS + 44,
             iconColor : this.wsSUCCESS,
             fontColor : this.wsSUCCESSDARK
           }
       )
     }



      return items
    },
    getStartNewTest() {

      if (!this.entity.test_unlimited) { return true }
      if ( this.entity.test_tries < 1 ) { return false }
      return true;
    },
    getSuccess() {
      if ( this.entity.student_test) {
        if (  this.entity.student_test.length > 0 ) {
          let hasSuccess = this.entity.student_test.find(el=> el.status === 'success')
          if ( hasSuccess ) { return true }
        }
      }

      return false
    },
    getTestInProcess() {
      if ( this.entity.student_test ) {
        let inProcess = this.entity.student_test.find(el => el.status === 'in_process')
        if ( inProcess ) {
          return true
        }
      }
      return false
    },
    getTestStartText() {
      if ( this.getTestInProcess ) {
        return this.$t('Proceed')
      }
      return this.$t('StartTest')
    },
    getBestTestResult() {
      if ( !this.entity.student_test ) {
        return null
      }
      let items = this.entity.student_test.map( el => el.result )
      let sorted = items.sort( (a,b) => a - b )

      return sorted[0]
    },
    getWorstTestResult() {
      if ( !this.entity.student_test ) {
        return null
      }
      let items = this.entity.student_test.map( el => el.result )
      let sorted = items.sort( (a,b) => b - a )
      return sorted[0]
    },
    getTries() {
      if ( this.entity.student_test) {
        return this.entity.test_tries - this.entity.student_test.length
      }
      return this.entity.test_tries;
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="d-flex justify-center pb-6">

    <div :class="[{'px-2' : SM  }]" style="max-width: 760px; width: 100%"  >

      <h5 class="mt-5 mb-2">
        <v-icon :color="wsACCENT">mdi-message</v-icon>
        {{ $t('Comments')}}
      </h5>

      <v-divider class="pb-5" :style="`border-color : ${wsBACKGROUND}`" />

      <v-sheet :style="`border: 1px solid ${wsBACKGROUND}`" class="wsRoundedLight pa">
        <ws-text-field
            v-model="entityData.text"
            solo :outlined="false" flat area>
        </ws-text-field>
        <div class="d-flex justify-end pr-3 pb-3 ">
          <ws-button @click="sendComment" label="LeaveComment" class="mb-1" />
        </div>
      </v-sheet>

      <component-comment
          v-for="(comment , i) in rootComments" :key="i"
          @reply="component.comments.unshift($event)"
          :comment="comment"
          :children="getChildComments(comment.uuid)"
      />

    </div>
  </div>
</template>

<script>
import componentComment from "@/components/pages/westudy/student/StudentCourse/UI/componentComment";
import {mapActions} from "vuex";

export default {
  name: "componentComments",
  components : {
    componentComment
  },
  props : {
    component: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed : {
    rootComments() {

      if ( !this.component.comments ) {
        return []
      }
      if ( this.component.comments.length === 0 ) {
        return []
      }

      let items = this.component.comments.filter(el => !el.parent)

      return items
    },
  },
  data() {
    return {
      entityData : {
        text : ''
      }
    }
  },
  methods : {
    ...mapActions('courses',['SEND_COMPONENT_COMMENT' , 'GET_COMMENTS']),

    getChildComments(commentId) {

      if ( !this.component.comments ) {
        return []
      }

      if ( this.component.comments.length === 0 ) {
        return []
      }
      return this.component.comments.filter(el => el.parent === commentId)
    },

    async sendComment() {
      this.entityData.component_id = this.component.uuid
      this.entityData.course_id = this.component.course_id
      let result = await this.SEND_COMPONENT_COMMENT(this.entityData)
      if ( !result ) {
        return
      }
      this.entityData.text = ''
      this.component.comments.unshift(result)
      this.notify(this.$t('CommentSent'))
    }
  }
}
</script>

<style scoped>

</style>
<template>
<div :style="displayFilter && admin ? `border : 1px solid ${wsBACKGROUND}` : null"
     :class="[{'px-6' : displayFilter && admin},{'py-3' : displayFilter && admin}]"
     class="mt-5 wsRoundedLight"
>

  <div v-if="displayFilter">
    <h4 class="text-center mb-3" :style="`color : ${wsACCENT}`">
    <span v-if="comment.schedule_id">
       {{  getFilterName('schedule_id' , comment.schedule_id) }}
      <span v-if="comment.payment_plan_id || comment.schedule_id"> , </span>
    </span>
      <span v-if="comment.payment_plan_id">
       {{  getFilterName('payment_plan_id' , comment.payment_plan_id) }}
        <span v-if="comment.schedule_id"> , </span>
    </span>
      <span v-if="comment.group_id">
       {{  getFilterName('group_id' , comment.group_id) }}
    </span>
    </h4>
    <v-divider :style="`border-color : ${wsBACKGROUND}`" class="mb-3" />
  </div>

  <div class="d-flex justify-space-between">
    <div class="d-flex align-center text-no-wrap">
      <v-avatar class="mr-3"  :color="wsBACKGROUND"
                size="40" key="profile_picture" >
        <v-img transition="xxx" v-if="comment.user_image" :src="comment.user_image">
          <template slot="placeholder">
            <v-img transition="xxx" src="@/assets/placeholder/user_blank.jpg" />
          </template>
        </v-img>
        <v-icon  dark v-else>mdi-account-tie</v-icon>
      </v-avatar>
      <div>
        <h5 :style="`color : ${wsACCENT}`">{{ comment.user_name }}    </h5>
        <h6 class="mt-1 grey--text"> {{ PARSE_TIME_STAMP(comment.date , true)}}</h6>
      </div>
    </div>
  </div>

  <h5 style="font-size: 16px; font-weight: 400" class="mt-4"> {{ comment.text}}</h5>
  <div class="d-flex">
    <h5
        v-if="!isChild"
        @click="displayReply = true"
        :style="`color : ${wsACCENT}`"
        class="pointer linkHover mr-3"
    >
      {{ $t('Reply') }}
    </h5>
    <h5 v-if="admin"
        @click="displayDeleteDialog = true"
        :style="`color : ${wsACCENT}`"
        class="pointer linkHover"
    >
      {{ $t('Delete') }}
    </h5>
  </div>

  <v-expand-transition>
    <div v-if="displayReply" class="pt-6">
      <v-sheet  :style="`border: 1px solid ${wsBACKGROUND}`" class="wsRoundedLight  mb-10">
        <ws-text-field
            v-model="entityData.text"
            solo :outlined="false" flat area>
        </ws-text-field>
        <div class="d-flex justify-end pr-3 pb-3 ">
          <v-btn @click="sendComment" :color="wsATTENTION" class="noCaps" dark> {{ $t('LeaveComment') }}</v-btn>
        </div>
      </v-sheet>
    </div>

  </v-expand-transition>

  <div class="pl-8 py-5" v-if="children.length > 0">
    <component-comment
        v-for="(child , i) in children" :key="i"
        @delete="$emit('delete',child)"
        :comment="child"
        :admin="admin"
        is-child
        @reply="$emit('reply' , $event)"
    />
  </div>

  <ws-dialog
      v-if="displayDeleteDialog"
      v-model="displayDeleteDialog"
      :title="$t('DeleteComment')"
      close-delete
      display-confirm-delete
      :confirm-delete-text="$t('DeleteCommentConfirmation')"
      @delete="deleteComment"
  >
  </ws-dialog>


</div>
</template>

<script>
import {mapActions} from "vuex";
import componentComment from "@/components/pages/westudy/student/StudentCourse/UI/componentComment";

export default {
  name: "componentComment",
  components : {
    componentComment
  },
  props : {
    comment : {
      type : Object,
      default() { return {} }
    },
    children : {
      type : Array,
      default() { return {} }
    },
    admin : {
      type : Boolean,
      default : false
    },
    waves : {
      type : Array,
      default() { return {} }
    },
    plans : {
      type : Array,
      default() { return {} }
    },
    groups : {
      type : Array,
      default() { return {} }
    },
    displayFilter : {
      type : Boolean,
      default : false
    },
    isChild : {
      type : Boolean,
      default : false
    }
  },

  data() {
    return {
      displayDeleteDialog : false,
      displayReply : false,
      entityData : {}
    }
  },
  methods : {
    ...mapActions('courses',['DELETE_COMMENT_STUDENT' , 'DELETE_COMMENT_ADMIN' , 'SEND_COMPONENT_COMMENT' , 'SEND_COMPONENT_COMMENT_ADMIN']),

    async sendComment() {

      this.entityData.component_id = this.comment.course_component_id
      this.entityData.parent = this.comment.uuid

      if ( this.admin ) {
        this.entityData.schedule_id = this.comment.schedule_id
        this.entityData.group_id = this.comment.group_id
        this.entityData.payment_plan_id = this.comment.payment_plan_id
      }

      let result = this.admin ? await this.SEND_COMPONENT_COMMENT_ADMIN(this.entityData) : await this.SEND_COMPONENT_COMMENT(this.entityData)
      if ( !result ) {
        return
      }

      this.entityData.text = ''
      this.displayReply = false
      this.notify(this.$t('CommentSent'))
      this.$emit('reply' , result )

    },

    getFilterName(filter , value) {
      let items = {
        group_id : this.groups,
        schedule_id: this.waves,
        payment_plan_id : this.plans
      }
      if ( Array.isArray(items[filter]) ) {
        let item = items[filter].find(el => el.value === value)
        if ( item ) {
          return item.text
        }
      }
      return ''
    },

    async deleteComment() {
      let result = this.admin ?  await this.DELETE_COMMENT_ADMIN(this.comment.uuid) : await this.DELETE_COMMENT_STUDENT(this.comment.uuid)
      if ( !result ) {
        return
      }
      this.$emit('delete')
      this.displayDeleteDialog = false
    }
  }
}
</script>

<style scoped>

</style>
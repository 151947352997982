<template>

  <div class="d-flex justify-center">
    <div style="max-width: 780px; width: 100%"  >

      <wsVideoViewer
          class="mt-5"
          v-if="component.entity_type === 'video' "
          :component="component"
      />

      <ws-image
          v-if="displayPdfImage"
          :file="component.file"
          :course-id="component.course_id"
          student
          class="my-5"
      />
      <ws-pdf
          v-if="displayPdfCondition && !displayPdf"
          :file="component.file"
          :course-id="component.course_id"
          student
          :height="!SM ? 600 : 300"
      />
      <ws-pdf-dialog
          v-model="displayPdf"
          :file="component.file"
          :course-id="component.course_id"
          student
      />
      <v-btn
          v-if="component.entity_type === 'video' && SM"
          @click="openVideo"
          :color="wsACCENT"
          block
          class="noCaps mt-5"
          outlined
      >
        {{ $t('OpenInVideoPlayer') }}
        <v-icon class="ml-2">mdi-open-in-new</v-icon>
      </v-btn>
      <v-sheet
          v-if="component.entity_type === 'file' "
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="wsRoundedHalf mt-5 pa-6 justify-space-between align-center"
          :class="!SM ? 'd-flex' : null "
      >

        <div class="d-flex align-center" >
          <v-icon class="mr-2" :color="wsACCENT" large>mdi-file</v-icon>
          <h5>{{ component.file.name }}</h5>
        </div>

        <v-btn
            @click="openFile(component)"
            :class="SM ? 'mt-5' : null "
            :color="wsACCENT"
            :block="SM"
            outlined class="noCaps" >
          {{ $t('Open') }}
        </v-btn>

      </v-sheet>
    </div>

    <!-- Display Photo Dialog-->
    <v-dialog v-model="displayImage"
              fullscreen>
      <v-sheet dark class="fill-height">
        <div class="d-flex justify-space-between py-2 px-3 align-center">
          <h6>{{ selectedImage.name }}</h6>
          <div>
            <v-btn v-if="!SM" small :href="selectedImage.src" class="noCaps mr-3"  outlined download type="attachment" target="_self">
              <v-icon small>mdi-download</v-icon>
              {{$t('Download')}}
            </v-btn>
            <v-btn :small="SM" icon @click="displayImage = false"><v-icon :small="SM" >mdi-close</v-icon></v-btn>
          </div>
        </div>
        <v-btn v-if="SM" block small :href="selectedImage.src" class="noCaps mb-3"  text download type="attachment" target="_self">
          <v-icon small>mdi-download</v-icon>
          {{$t('Download')}}
        </v-btn>
        <v-img transition="xxx" :src="selectedImage.src" />
      </v-sheet>
    </v-dialog>

  </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "lectureViewer",
  props : {
    component : {
      type : Object,
      dafault() { return {} }
    }
  },
  data() {
    return {
      displayImage : false,
      selectedImage : {},
      displayPdf : false,
    }
  },
  computed : {
    displayPdfImage() {
      if ( this.component.file ) {
        if ( this.component.file.mime ) {
          if ( this.component.file.mime.includes('image')) {
            return true
          }
        }
      }

      return false
    },
    displayPdfCondition() {

      if ( this.component.file ) {
        if ( this.component.file.mime ) {
          if ( this.component.file.mime.includes('pdf')) {
            return true
          }
        }
      }

      return false
    }
  },
  methods : {
    ...mapActions('upload', [
      'GET_STUDENT_COMPONENT_FILE',
      'GET_STUDENT_REPLY_FILE_SELF',
    ]),

    hasAudio(file) {
      if ( !file ) {
        return false
      }
      if ( !file.mime) {
        return false
      }
      return file.mime.includes('audio')

    },

    async openFile(component ) {

      if ( component.file ) {
        if ( component.file.mime ) {
          if ( component.file.mime.includes('pdf')) {
            this.displayPdf = true
            return
          }
        }
      }

      let file = component.file
      let data =  {
        file : file.uuid,
        course : component.course_id
      }
      let result = await this.GET_STUDENT_COMPONENT_FILE(data)
      if (!result) { return this.notify('File Not found') }

      component.passed = true

      if ( this.hasAudio(component.file) ) {
        this.$store.state.audioPlayer.src = result.url
        this.$store.state.audioPlayer.title = component.name
        this.$store.state.audioPlayer.display = true

        return
      }

      var a = document.createElement('a');
      a.href = result.url;
      a.download = result.name;
      a.target = '_self'
      if (result.mime.includes('image')) {
        this.displayImage = true
        this.selectedImage = {
          src : result.url,
          name : component.name
        }
      } else {
        a.setAttribute('download',result.name);
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

    },
    openVideo() {
      this.$store.state.videoPlayer.component = this.component
      this.$store.state.videoPlayer.display = true
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div>

    <div class="d-flex justify-center">
      <div style="max-width: 780px; width: 100%"  >

        <!-- Task Completion Message-->
        <v-sheet
            v-if="component.passed"
            :color="wsSUCCESS + '44'"
            class="wsRoundedHalf pa-3 px-6 mb-5"
            :style="`border : 1px solid ${wsSUCCESS}`"

        >
          <div class="d-flex align-center justify-space-between">
            <h4 :style="`color : ${wsSUCCESSDARK}`">{{ $t('IsPassed') }} </h4>
            <v-icon v-if="!component.result" :color="wsSUCCESS">mdi-check</v-icon>

            <v-chip v-else outlined  :color="wsSUCCESSDARK" class="mr-3" dark>
              <h4  class=" text-no-wrap d-flex align-center">
                <v-icon  class="mr-1">mdi-star</v-icon>
                {{ component.result }}
              </h4>
            </v-chip>
          </div>

        </v-sheet>

        <!-- Task Self Control Message and Swtich-->
        <v-sheet
            v-if="entity.student_self_control"
            :color="wsLIGHTCARD"
            :style="`border : 1px solid ${wsBACKGROUND}`"
            :class="[{'d-flex' : !SM}]"
            class="pa-4 justify-space-between align-center wsRoundedHalf mb-6"
        >
         <div>
           <h4 :style="`color : ${wsACCENT}`"> {{ $t('TaskSelfControl') }} </h4>
           <h5 class="font-weight-regular">
             {{ $t(!(entity.student_reply || entity.student_upload) ? 'SelfControlInstruction' : 'SelfControlUploadInstruction') }}
           </h5>
         </div>


          <ws-switch
              v-if="!(entity.student_reply || entity.student_upload)"
              @input="selfControlComplete" v-model="component.passed" >
            <h5>{{ component.passed ?  $t('IsPassed') : $t('IsNotPassed')  }}</h5>
          </ws-switch>
        </v-sheet >

        <video-task-viewer
            v-if="entity.type === 'video' "
            :entity="entity"
        />
        <test-task-viewer
            v-if="entity.type === 'test' "
            :entity="entity"
        />

        <wsTextViewer
            v-if="entity.description"
            :value="entity.description"
            :html="entity.text_description"
            :course-id="entity.course_id"
            student
            class="pb-6"
        />



      </div>
    </div>

    <task-reply
        v-if="entity.student_reply || entity.student_upload"
        :entity="entity"
        :course-id="entity.course_id"
        @success="handleReply"
    />

  </div>
</template>

<script>
import {mapActions} from "vuex";
import videoTaskViewer from "@/components/pages/westudy/student/StudentCourse/components/task/videoTaskViewer";
import testTaskViewer from "@/components/pages/westudy/student/StudentCourse/components/task/testTaskViewer";
import taskReply from "@/components/pages/westudy/student/StudentCourse/components/task/taskReply";

export default {
  name: "lectureViewer",
  components : {
    videoTaskViewer,
    testTaskViewer,
    taskReply,
  },
  props : {
    component : {
      type : Object,
      default() { return {} }
    }
  },

  data() {
    return {
      entity : {}
    }
  },
  methods : {

    ...mapActions('courses', [
      'GET_TASK_STUDENT',
    ]),
    ...mapActions('evaluation', [
      'EDIT_COMPONENT_EVALUATION_SELF_CONTROL'
    ]),

    async handleReply() {
      if ( this.entity.student_self_control && !this.component.passed ) {
        await this.selfControlComplete(true)
        let data = this.COPY(this.component)
        data.passed = true
        this.$emit('update' , this.COPY(data) )
      }
    },

    async selfControlComplete($event) {
      let data = {
        uuid : this.component.uuid,
        result_id : this.component.result_id,
        result_passed : $event
      }
      let result = await this.EDIT_COMPONENT_EVALUATION_SELF_CONTROL(data)
      if ( !result ) {
        return
      }

      this.$emit('update' , this.component)
    },
    async initPage() {
      this.selectedLang = this.$i18n.locale
      if ( !this.component.task ) {  return }

      let result = await this.GET_TASK_STUDENT(this.component.task.value);
      if ( !result ) {
        this.displayEntity = false
      }

      this.$store.state.tests.store_selectedTask = result.task.uuid
      if ( result.task.name ) {
        this.$store.state.tests.store_selectedTaskName = result.task.name[this.$i18n.locale]
      }

      this.entity = result.task

      this.READ_EVENTS({ type : 'student_evaluation_success', entity : this.componentId })
      this.READ_EVENTS({ type : 'student_teacher_reply', entity : this.componentId })

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>
<template>
  <div>

    <!-- Video Task Content -->

    <v-sheet
        :color="wsLIGHTCARD"
        :style="`border : 1px solid ${wsBACKGROUND}`"
        class="wsRoundedHalf pa-6 mb-5"
    >

      <div class="d-flex justify-space-between align-center">
        <div class="d-flex">
          <v-icon :color="wsACCENT" class="mr-2">mdi-video</v-icon>
          <h3 class="font-weight-medium">{{ $t('VideoMeeting')}} </h3>
        </div>
        <h3 class="font-weight-medium">{{ getVideoTypeName(entity.video_type) }} </h3>
      </div>

      <ws-text-field
          @click="$event.target.select(); copy(entity.video_url)"
          :value="entity.video_url"
          :label="$t('Link')"
          readonly
          append-icon="mdi-content-copy"
          prepend-inner-icon="mdi-link"
          class="mt-9 pointer" hide-details  outlined
      />

      <ws-text-field
          @click="$event.target.select(); copy(entity.video_url)"
          :label="$t('Password')"
          :value="entity.video_password"
          append-icon="mdi-content-copy"
          prepend-inner-icon="mdi-form-textbox-password"
          class="mt-3 pointer" hide-details readonly outlined
      />


      <div class="d-flex mt-5">
        <v-icon :color="wsACCENT" class="mr-2">mdi-calendar</v-icon>
        <h5 :style="`color : ${wsACCENT}`" class="font-weight-medium">{{ $t('Date')}} : <span class="font-weight-bold text-capitalize">{{ entity.video_date }}</span> </h5>
      </div>


      <div class="d-flex mt-5">
        <v-icon :color="wsACCENT" class="mr-2">mdi-clock</v-icon>
        <h5  :style="`color : ${wsACCENT}`" class="font-weight-medium">{{ $t('Time')}} : <span class="font-weight-bold text-capitalize">{{ entity.video_time }}</span> </h5>
      </div>




      <v-btn  :color="wsACCENT" outlined block large class="mt-9 mb-1 noCaps"
              :href="entity.video_url" target="_blank" >
        {{ $t('Goto') }}
        <v-icon class="ml-2">mdi-open-in-new</v-icon>
      </v-btn>

    </v-sheet>

  </div>
</template>

<script>
export default {
  name: "videoTaskViewer",
  props : {
    entity : {
      type : Object,
      default() { return {} }
    }
  },
  methods : {
    getVideoTypeName(type) {
      let videoType = this.TASK_VIDEO_TYPES.find(el=>el.value === type)
      return videoType ? videoType.text : ''
    },
    async copy(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.notify(this.$t('CopiedToClipboard') ,null, 'mdi-clipboard-edit-outline' )
      } catch($e) {
        this.notify(this.$t('Error') )
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="d-flex justify-center">
    <!-- Lecture Content-->
    <div style="max-width: 780px; width: 100%"  >

      <lecture-topic-viewer
          v-for="(topic, i ) in entity.topics"  :key="'topic' + i"
          v-intersect="intersector"
          :topic="topic"
          :course-id="entity.course_id"
          class="mb-5"
          :ref="'topic_' + i"
          :id="'topic_' + i"
          :expand-trigger="expandTrigger[i]"
      />

      <!-- OLD FILES Content -->
      <v-btn v-for="(file,i) in entity.files "
             @click="openFile(file )"
             :color="wsACCENT"
             class="noCaps ml-3 px-1"
             text
             :key="i + 'reply_file'"
      ><v-icon class="mr-3">mdi-paperclip</v-icon>
        {{ SHORTEN_FILE_NAME(file.name) }}
      </v-btn>


    </div>
    <!-- Topics Navigation-->
    <v-sheet v-if="!SM"
             :style="`position: fixed; left: ${$store.state.dashboard.sideMenu ? 280 : 75 }px; z-index: 999; transition : all 0.3s ease`">
      <v-hover v-slot="{hover}">
        <div>

          <v-slide-x-transition mode="out-in">

            <v-sheet key="nav_lines" v-if="!hover" color="transparent" min-height="100px">
              <v-sheet
                  color="transparent"
                  v-for="(_,i) in entity.topics"
                  :key="'nav_module' + i"
                  class="pointer">

                <v-sheet
                    :color="intersectedItem === ('topic_' + i ) ? wsATTENTION : wsBACKGROUND "
                    class="my-4"
                    :width="intersectedItem === ('topic_' + i ) ? 32 : 16 "
                    height="px">
                  <hr style="opacity: 0 "
                  >
                </v-sheet>

              </v-sheet>
            </v-sheet>
            <v-sheet max-height="500" class="overflow-y-auto" width="154" key="nav_text" v-else>

              <template v-for="(topic,i) in entity.topics">
                <v-hover v-slot="{hover}" :key="'text_topic_' + i">
                  <h5 @click="navigate(i)"
                      :style="`color : ${hover ? wsATTENTION : wsACCENT}`"
                      style="font-size: 11px; font-weight: 700"
                      class="ml-4 pointer mb-3"> {{ topic.name.slice(0, 16) }}</h5>
                </v-hover>

              </template>

            </v-sheet>

          </v-slide-x-transition>

        </div>

      </v-hover>
    </v-sheet>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import lectureTopicViewer from "@/components/pages/westudy/student/StudentCourse/components/lecture/lectureTopicViewer";

export default {
  name: "lectureViewer",
  components : {
    lectureTopicViewer
  },
  props : {
    component : {
      type : Object,
      dafault() { return {} }
    }
  },
  data() {
    return {
      intersectedItem: 'topic_0',
      entity : {
        topics : []
      },
      expandTrigger : []
    }
  },
  watch : {
    component : {
      handler() {
        this.initPage()
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('courses',[
        'GET_LECTURE_STUDENT'
    ]),
    ...mapActions('upload', [
      'GET_STUDENT_COMPONENT_FILE',
      'GET_STUDENT_REPLY_FILE_SELF',
    ]),

    navigate(index) {

      if (!this.$refs['topic_' + index]) {
        return
      }

      this.$set(this.expandTrigger,index,(this.expandTrigger[index]+ 1) )

      let container = document.getElementById('dashPage')
      if (!container) {
        return
      }
      this.intersectedItem = 'topic_' + index

      setTimeout( this.$vuetify.goTo(this.$refs['topic_' + index][0],
          {
            duration: 300,
            offset: 70,
            easing: 'easeInOutQuad',
            container: container
          }), 300)


    },
    intersector(entries) {
      if (entries[0]) {
        if (!entries[0].isIntersecting && entries[0].boundingClientRect.y < 0) {
          console.log(entries[0].boundingClientRect.y)
          this.intersectedItem = entries[0].target.id
        }
        if (entries[0].isIntersecting && entries[0].boundingClientRect.y < 0) {
          console.log(entries[0].boundingClientRect.y)
          this.intersectedItem = entries[0].target.id
        }
      }
    },

    async initPage() {

      if ( !this.component.lecture ) { return }

      let result = await this.GET_LECTURE_STUDENT(this.component.lecture.value)
      if (!result) {
        return
      }
      this.expandTrigger = []
      result.lecture.topics.forEach(()=>this.expandTrigger.push(0))

      this.entity = result.lecture

    },
    async openFile(file , reply = false) {
      let data =  {
        file : file.uuid,
        course : this.entity.course_id
      }
      let result = !reply ? await this.GET_STUDENT_COMPONENT_FILE(data) : await this.GET_STUDENT_REPLY_FILE_SELF(data)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;

      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  },
  mounted() {
    this.initPage()
  }

}
</script>

<style scoped>

</style>
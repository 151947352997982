<template>
  <div>

    <div class="d-flex justify-center">
      <div style="max-width: 780px; width: 100%"  >
        <h5 class="mt-5 mb-2">
          <v-icon :color="wsACCENT">mdi-notebook-edit</v-icon>
          {{ $t('TaskReply')}}
        </h5>
      </div>
    </div>


    <v-divider class="pb-5" :style="`border-color : ${wsBACKGROUND}`" />

    <template v-if="entity.student_reply">
      <div class="d-flex justify-center">
        <div style="max-width: 780px; width: 100%"  >
          <div v-for="(reply, i) in entity.replies" :key="i">
            <div class="d-flex align-center text-no-wrap">
              <v-avatar class="mr-3"  :color="wsBACKGROUND"
                        size="40" key="profile_picture" >
                <v-img transition="xxx" v-if="reply.img" :src="reply.img">
                  <template slot="placeholder">
                    <v-img transition="xxx" src="@/assets/placeholder/user_blank.jpg" />
                  </template>
                </v-img>
                <v-icon  dark v-else>mdi-account-tie</v-icon>
              </v-avatar>
              <div>
                <h5 :style="`color : ${wsACCENT}`">{{ reply.name || (reply.is_teacher ? $t('TeacherReply') : '') }}    </h5>
                <h6 class="mt-1 grey--text"> {{ PARSE_TIME_STAMP(reply.date)}}</h6>
              </div>
            </div>

            <ws-text-viewer
                class="mt-3"
                v-model="reply.content"
                :course-id="courseId"
                student
            />
          </div>

          <v-sheet
              :style="`border : 1px solid ${wsDARKLIGHT}`"
              style="position:relative;"
              class="pa-3 wsRoundedLight"
              min-height="150"
          >
            <ws-text-editor
                class="mb-16"
                v-model="replyData.content"
                :placeholder="$t('TaskReplyPlaceholder')"
            />
            <v-sheet v-if="SM" color="transparent" height="50" />

            <v-btn
                style="position: absolute ; bottom: 15px; right: 15px"
                @click="displayPublishReply"
                :color="wsATTENTION"
                elevation="0"
                class="noCaps"
                dark
            >
              {{ $t('SendAnswer') }}
            </v-btn>

          </v-sheet>


        </div>
      </div>

    </template>

   <!-- Dialogs-->
    <ws-dialog
        v-model="displayDialog"
        :title="$t('PublishStudentTaskReply')"
        @save="saveReply"
        :save-text="$t('Publish')"
        :cancel-text="$t('GoBack')"
        @cancel="displayDialog = false"
    >
      <h4 class="py-6 text-center">{{ $t('StudentTaskReplyConfirmationText') }}</h4>
    </ws-dialog>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "taskReply",
  props : {
    entity : {
      type : Object,
      default() { return {} }
    },
    courseId :  {
      type : String
    }
  },
  data() {
    return {
      displayDialog : false,
      replyData : { content : [] }
    }
  },
  methods : {
    ...mapActions('courses', [
      'ADD_EDIT_STUDENT_TASK_REPLY'
    ]),

    async saveReply() {

      if (!this.replyData.content) {
        return this.notify(this.$t('PleaseEnterAnswer'))
      }
      if (this.replyData.content.length === 0) {
        return this.notify(this.$t('PleaseEnterAnswer'))
      }

      let data = {
        task_id : this.entity.uuid,
        content : this.replyData.content
      }

      let result = await this.ADD_EDIT_STUDENT_TASK_REPLY(data)
      if ( !result ) {
        return this.notify(this.$t('Error'))
      }

      this.entity.replies.push(result)
      this.replyData.content = []
      this.notify(this.$t('StudentTaskReplyPublished') , 'success')
      this.displayDialog = false
      this.$emit('success')

    },
    async changeStudentReply($event) {

      let data = {
        uuid : this.entity.student_task_reply.uuid || null,
        task_id : this.entity.uuid,
        content : $event
      }
      let result = await this.ADD_EDIT_STUDENT_TASK_REPLY(data)
      if ( !result ) {
        return
      }
      if ( !this.entity.student_task_reply.uuid ) {
        this.entity.student_task_reply.uuid = result.uuid
      }
    },
    displayPublishReply() {
      this.displayDialog = true
    }
  }
}
</script>

<style scoped>

</style>